






















































































import { Observer } from "mobx-vue";
import { Component, Inject, Vue } from "vue-property-decorator";
import { MyCharacterViewmodel } from "../viewmodels/my-character-viewmodel";
import { Classes, MonsterTypes, CustomRaces } from "@/constants";

@Observer
@Component({
  components: {
    MyCharacterList: () => import("@/modules/my-character/components/my-character-list.vue"),
  },
})
export default class AllCharacterTab extends Vue {
  @Inject() vm!: MyCharacterViewmodel;
  tab = 1;

  monsterTypes = MonsterTypes;
  classes = Classes;
  races = CustomRaces;

  id = "";
  type = "";
  monsterClass = "";
  race = "";

  search() {
    this.vm.searchMyMonsters({
      id: this.id,
      type: this.type,
      monsterClass: this.monsterClass,
      race: this.race,
      page: 1,
    });
  }
}
